import * as Sentry from '@sentry/sveltekit';
import { handleErrorWithSentry } from '@sentry/sveltekit';

Sentry.init({
	dsn: 'https://63123e83ce39b859db8b74f0f79dd9cf@o4508780930596864.ingest.us.sentry.io/4508790367911937',
	denyUrls: [/localhost(:[0-9]+)?/, /.*?bench-computing\.vercel\.app(\/.*)?$/],
	enabled: process.env.NODE_ENV !== 'development'
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
